import React from 'react';
import { Link } from 'gatsby';
import { first } from 'lodash-es';
import styled from '../styled';
import Footer from '../components/Footer';
import withRoot from '../withRoot';
import { Typography } from '@material-ui/core';
import { CookieBar } from '../components/CookieBar';
import { IArticleDetail, ICategory } from '../services/helpscout';
import Navigation from '../components/Navigation';
import { ArrowRight } from 'react-feather';

type IProps = {
  articles: IArticleDetail[];
  categories: ICategory[];
  currentCategory?: ICategory;
  currentArticle?: IArticleDetail;
  content: React.ReactNode;
};

const ExternalLink = styled('a')`
  color: ${(props) => props.theme.palette.primary.main};
  border-bottom: 1px solid;
`;

const Layout = styled('div')`
  display: grid;
  grid-template-columns: 1fr 750px 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(4)}px;
  margin-top: 100px;
  margin-bottom: 100px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

const NavWrapper = styled('nav')`
  padding: ${(p) => p.theme.spacing(3)}px ${(p) => p.theme.spacing(6)}px;
  border-right: 1px solid ${(p) => p.theme.palette.grey[100]};
`;

const Content = styled('div')`
  background-color: #fff;
  max-width: 850px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1000px) {
    margin: 0 12px;
  }
`;

const CategoryList = styled('ul')`
  color: #fff;
  padding: 0;
  list-style: none;
  max-width: 350px;

  strong {
    font-weight: 700;
    color: ${(p) => p.theme.palette.primary.main};

    svg {
      margin-left: ${(p) => p.theme.spacing(0.5)}px;
    }
  }

  li ul {
    list-style: none;
    padding: 0;
    margin-top: ${(p) => p.theme.spacing(1)}px;
  }

  li li {
    margin-left: ${(p) => p.theme.spacing(3.7)}px;
    border-bottom: 2px transparent;

    strong {
      color: ${(p) => p.theme.palette.primary.main};
      font-weight: bold;
    }

    a {
      color: ${(p) => p.theme.palette.grey[900]};
      font-size: 16px;
    }
  }

  li {
    margin-bottom: ${(p) => p.theme.spacing(2)}px;
  }

  li a {
    font-size: 18px;
    color: ${(p) => p.theme.palette.grey[900]};

    &:hover {
      color: ${(p) => p.theme.palette.primary.main};
    }
  }
`;

const RelatedArticlesWrapper = styled('div')`
  padding-top: ${(p) => p.theme.spacing(3)}px;
  padding-right: ${(p) => p.theme.spacing(3)}px;
`;

const CategoryNav = ({
  categories,
  articles,
  currentCategory,
  currentArticle
}: {
  categories: ICategory[];
  articles: IArticleDetail[];
  currentCategory?: ICategory;
  currentArticle?: IArticleDetail;
}) => {
  const currentCategoryId =
    currentArticle || currentCategory
      ? first(currentArticle && currentArticle.categories) ||
        (currentCategory && currentCategory.categoryId)
      : null;

  const referenceOrder = ['faqs', 'policies'];

  categories.sort((a, b) => {
    return referenceOrder.indexOf(a.slug) > referenceOrder.indexOf(b.slug)
      ? 1
      : -1;
  });

  return (
    <div>
      <Typography variant="body1" component="p">
        <strong>Categories</strong>
      </Typography>
      <CategoryList>
        {categories
          .filter((c) => c.slug !== 'uncategorized')
          .map((category) => (
            <li key={category.id}>
              <Link to={`/qs/${category.slug}/`}>
                {category.categoryId === currentCategoryId ? (
                  <strong>{category.name}</strong>
                ) : (
                  category.name
                )}
              </Link>
            </li>
          ))}
      </CategoryList>
    </div>
  );
};

const HC = ({
  content,
  articles,
  categories,
  currentCategory,
  currentArticle
}: IProps) => {
  return (
    <div>
      <Navigation />
      <Layout>
        <NavWrapper>
          <CategoryNav
            articles={articles}
            categories={categories}
            currentArticle={currentArticle}
            currentCategory={currentCategory}
          />
        </NavWrapper>
        <Content>
          <main>{content}</main>
        </Content>
        <RelatedArticlesWrapper>
          <Typography variant="body1" component="p" paragraph>
            <strong>Still have questions?</strong>
          </Typography>
          <Typography variant="body2" component="p" paragraph>
            Contact our team anytime via our{' '}
            <Link to="/contact/">contact form</Link> or at{' '}
            <ExternalLink href="mailto:success@affilimate.com">
              success@affilimate.com
            </ExternalLink>
            .
          </Typography>
          <br />
          <Typography variant="body1" component="p" paragraph>
            <strong>Already have an account?</strong>
          </Typography>
          <Typography variant="body2" component="p" paragraph>
            Log in to your account to access the{' '}
            <ExternalLink
              href="https://app.affilimate.com/docs/"
              target="_blank"
            >
              publisher knowledge base <ArrowRight size={12} />
            </ExternalLink>
          </Typography>
        </RelatedArticlesWrapper>
      </Layout>
      <Footer />
      <CookieBar />
    </div>
  );
};
export const HelpCenter = withRoot(HC);
